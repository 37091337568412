import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, { title: "Site Details" }, {
    default: _withCtx(() => [
      (_ctx.auth_user)
        ? (_openBlock(), _createBlock(_component_DataTable, {
            key: 0,
            loading: _ctx.loading === 'loading',
            data: _ctx.items.data,
            columns: _ctx.fields,
            pagination: _ctx.items,
            onEdit: _ctx.editRecord,
            onCreate: _ctx.createRecord,
            onFilter: _ctx.filterRecord,
            onDestroy: _ctx.deleteRecord
          }, null, 8, ["loading", "data", "columns", "pagination", "onEdit", "onCreate", "onFilter", "onDestroy"]))
        : (_openBlock(), _createBlock(_component_DataTable, {
            key: 1,
            loading: _ctx.loading === 'loading',
            data: _ctx.items.data,
            columns: _ctx.fields,
            pagination: _ctx.items,
            onEdit: _ctx.editRecord,
            onFilter: _ctx.filterRecord,
            showCreateButton: false,
            onDestroy: _ctx.deleteRecord
          }, null, 8, ["loading", "data", "columns", "pagination", "onEdit", "onFilter", "onDestroy"]))
    ]),
    _: 1
  }))
}