
import { useStore } from 'vuex'
import { defineComponent, ref, onMounted, computed, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import axios from '../../../../axios'

export default defineComponent({
  components: {},
  setup() {
    const store = useStore()
    const router = useRouter()
    const auth_user = ref(true)

    const fields = ref([
      {
        title: 'Arnoc',
        dataIndex: 'arnoc',
      },
      {
        title: 'Ship To',
        dataIndex: 'ship_to',
      },
      {
        title: 'Site Name',
        dataIndex: 'site_name',
      },
      {
        title: 'Retailer User',
        slots: { customRender: 'site_index_retailer_users' },
        dataIndex: 'retailers',
      },
      {
        title: 'Retailer Owner',
        dataIndex: 'retailer_owner.retailer_name',
      },
      {
        title: 'Type',
        dataIndex: 'type',
      },
      {
        title: 'Cluster',
        dataIndex: 'cluster.cluster_name',

      },
      {
        title: 'Zone',
        dataIndex: 'zone.name',

      },
      {
        title: 'Territory Manager',
        dataIndex: 'zone.territory_manager.full_name',

      },
      {
        title: 'District',
        dataIndex: 'zone.district.district_name',

      },
      {
        title: 'District Manager',
        dataIndex: 'zone.district.district_manager.full_name',

      },
      // {
      //   title: "Is Active",
      //   dataIndex: "is_active",
      //   align: 'center',
      //   slots: { customRender: "is_active" }
      // },
      {
        title: 'Action',
        dataIndex: 'action',
        slots: { customRender: 'action' },
        align: 'center'
      },
    ])

    const index = (payload = { page: 1 }) => {
      store.dispatch('SiteDetails/index', payload)
    }

    const filterRecord = (payload: any) => {
      index(payload)
    }

    const createRecord = () => {
      router.push('/site-details/create')
    }

    const editRecord = (record: any) => {
      router.push(`/site-details/edit/` + record.id)
    }

    const deleteRecord = (record: any) => {
      store.dispatch('SiteDetails/destroy', record.id).catch((e) => {
        console.log(e.response.status)
      })
    }

    const getUser = async () => {
      const url = 'auth/user'
      const response = await axios.get(url)
      const user = response.data.data

      const { role } = toRefs(user)
      role.value = user.role.view
      auth_user.value = (role.value == 'Admin')
    }

    const scrollToTop = () => {
      window.scrollTo(0, 0)
    }

    const items = computed(() => store.getters['SiteDetails/items'])
    const loading = computed(() => store.getters['SiteDetails/status'])

    onMounted(() => {
      getUser()
      index()
      scrollToTop()
    })

    return {
      loading,
      items,
      fields,
      auth_user,
      filterRecord,
      createRecord,
      editRecord,
      deleteRecord
    }
  },
})
